<template>
  <div>
    <b-card>
      Nesta página você pode cadastrar alguns medicamentos mais usados para facilitar a
      criação de lembretes de medicamentos para seus pacientes.
    </b-card>

    <b-card
      no-body
      class="mb-0 mt-2"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-start"
          >
            <b-button
              variant="primary"
              @click="create"
            >
              Adicionar novo medicamento favorito
            </b-button>
          </b-col>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                type="text"
                placeholder="Busque por um medicamento favorito"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col>
          <h4 class="ml-2 mt-1">
            Lista de medicamentos favoritos
          </h4>
        </b-col>
        <b-col
          v-if="selected.length !== 0"
          cols="4"
          class="mr-2 d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            :disabled="selected.length > 1"
            @click="edit"
          >
            Editar
          </b-button>
          <b-button
            class="ml-2"
            variant="outline-danger"
            @click="show = true"
          >
            Excluir
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="refFavoriteListTable"
        class="position-relative mt-2"
        :items="fetchFavorites"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="
          searchQuery.length === 0
            ? 'Você ainda não adicionou um medicamento favorito.'
            : 'Nenhum medicamento favorito encontrado.'
        "
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(medicamento)="data">
          {{ data.item.medication_info.name }}
        </template>

        <template #cell(horario)="data">
          <div class="pre-formatted">
            {{
              time(
                data.item.template_schedule_info.schedule_times_type,
                data.item.template_schedule_info.schedule_times
              )
            }}
          </div>
        </template>

        <template #cell(frequência)="data">
          <template v-if="data.item.template_schedule_info.schedule_week_days.length > 0">
            {{ frequency(data.item.template_schedule_info.schedule_week_days) }}
          </template>
          <template v-else>
            {{ frequency(data.item.template_schedule_info.type) }}
          </template>
        </template>

        <template #cell(duração)="data">
          {{ duration(data.item.template_schedule_info.duration_type) }}
        </template>

        <template #cell(ação)="data">
          <b-form-checkbox
            v-model="selected"
            :value="data.item.template_schedule_info.id"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalFavorites"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      v-model="show"
      centered
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      ok-title="Excluir"
      hide-header
      @ok="remove"
    >
      <b-card-text class="text-center">
        <feather-icon
          icon="AlertCircleIcon"
          stroke="red"
          size="60"
        />
        <div class="mt-2">
          <strong>Atenção!</strong>
        </div>
        <div class="mt-2">
          Tem certeza de que deseja excluir este(s) medicamento(s) favoritos?
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BCardText,
  BPagination,
  VBModal,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useFavoritesList from './useFavoritesList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormCheckbox,
    BCardText,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchFavorites,
      tableColumns,
      perPage,
      currentPage,
      totalFavorites,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFavoriteListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useFavoritesList()

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchFavorites,
      tableColumns,
      perPage,
      currentPage,
      totalFavorites,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFavoriteListTable,
      refetchData,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },

  data() {
    return {
      selected: [],
      show: false,
    }
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-help')
      }
    },
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    create() {
      this.$store.commit('formMedication/RESET')
      this.$store.commit('formMedication/UPDATE_LINK', 'favorite')
      this.$router.push('favorite-medication')
    },
    edit() {
      this.$store.commit('formMedication/UPDATE_ID_SELECT', this.selected)
      this.$router.push('favorite-edit')
    },
    remove() {
      this.$store
        .dispatch('favorite/remove', { data: this.selected })
        .then(() => {
          this.$refs.refFavoriteListTable.refresh()
          this.selected = []
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    time(type, times) {
      let text = ''
      let interval = ''
      if (type === 'FIXED') {
        switch (times.length) {
          case 1:
            interval = '1x ao dia (de 24 em 24 horas)'
            break
          case 2:
            interval = '2x ao dia (de 12 em 12 horas)'
            break
          case 3:
            interval = '3x ao dia (de 8 em 8 horas)'
            break
          case 4:
            interval = '4x ao dia (de 6 em 6 horas)'
            break
          case 6:
            interval = '6x ao dia (de 4 em 4 horas)'
            break
          case 12:
            interval = '12x ao dia (de 2 em 2 horas)'
            break
          case 24:
            interval = '24x ao dia (de 1 em 1 hora)'
            break
          default:
        }
        text += interval
      }

      if (times.length > 0) {
        for (let i = 0; i < times.length; i += 1) {
          if (text !== '') {
            text += '\n'
          }
          text += `${times[i].time} | ${times[i].dose}`
        }
      }
      return text
    },
    frequency(value) {
      let text = ''
      switch (value) {
        case 'RECURRENT':
          text = 'Dias contínuos (sem pausas)'
          break
        case 'ALTERNATED':
          text = 'Dias alternados (4 em 4 dias, 7 em 7…)'
          break
        case 'CUSTOM':
          text = 'Dias específicos da semana'
          break
        default:
          text = ''
      }
      if (text === 'Dias específicos da semana') {
        const arrayWeek = []
        for (let i = 0; i < value.length; i += 1) {
          switch (value[i]) {
            case 'sunday':
              arrayWeek.push('dom')
              break
            case 'monday':
              arrayWeek.push('seg')
              break
            case 'tuesday':
              arrayWeek.push('ter')
              break
            case 'wednesday':
              arrayWeek.push('qua')
              break
            case 'thursday':
              arrayWeek.push('qui')
              break
            case 'friday':
              arrayWeek.push('sex')
              break
            case 'saturday':
              arrayWeek.push('sab')
              break
            default:
          }
        }
        const lengthArray = arrayWeek.length
        let stringWeek = ''
        arrayWeek.forEach((valueArray, index) => {
          stringWeek += valueArray
          if (index < lengthArray - 2) {
            stringWeek += ', '
          } else if (index === lengthArray - 2) {
            stringWeek += ' e '
          }
        })
        return stringWeek
      }
      if (text === 'Dias alternados (4 em 4 dias, 7 em 7…)') {
        return ''
      }
      return text
    },
    duration(value) {
      let text = ''
      switch (value) {
        case 'CONTINUOUS':
          text = 'Uso contínuo (sem data de término)'
          break
        case 'INTERVAL':
          text = 'Quantidade de dias (início e fim definidos)'
          break
        default:
          text = ''
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .pre-formatted {
    white-space: pre;
  }
  ::v-deep .modal-footer {
    justify-content: center;
  }
</style>
